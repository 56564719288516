<template>
    <div class="sitemap-discounts-index">
        <h3 class="sitemap-discounts-index__title">{{ months[sitemap.month - 1] }} {{ sitemap.year }}</h3>
        <ul class="sitemap-discounts-index__letter-list">
            <li v-for="(discount, keyB) in sitemap.discounts" :key="keyB">
                <NuxtLink :to="variants[discount.type].path + '/' + discount.slug">{{
                    discount.title
                }}</NuxtLink>
            </li>
        </ul>
        <ButtonGeneric
            class="max-w-max border-site-primary p-0 text-sm font-semibold text-site-primary"
            :info="{ text: 'Regresar', link: '/sitemap/promociones', icon: $assets.primary.arrowLeft }"
        />
    </div>
</template>

<script lang="ts" setup>
import { useVariantsDictionary } from '~/composables/VariantsDictionary'
const { variants } = useVariantsDictionary()

const Route = useRoute()

const months = [
    'enero',
    'febrero',
    'marzo',
    'abril',
    'mayo',
    'junio',
    'julio',
    'agosto',
    'septiembre',
    'octubre',
    'noviembre',
    'diciembre',
]

const [month, year] = (Route.params.slug as string).split('-')

if (!months.includes(month)) {
    navigateTo('/sitemap/promociones', {
        redirectCode: 302,
    })
}

type SitemapPromos = {
    discounts: { title: string; slug: number; type: 'coupon' | 'offer' }[]
    month: number
    year: number
}

const { buildHeaders, baseURL, endpoints } = useApiConfig()

const { data: responseData, error } = await useAsyncData('stores-sitemap-data', () => {
    return $fetch<SitemapPromos>(endpoints.pages.siteMap.discounts, {
        headers: buildHeaders(),
        method: 'GET',
        baseURL,
        params: {
            month: months.indexOf(month) + 1,
            year: year,
        },
    }).catch((e) => e.data || false)
})

if (error.value || !responseData.value || responseData.value.error) {
    throw createError({
        statusCode: responseData.value?.feedback === 'resource_not_found' ? 404 : 500,
        message:
            responseData.value?.feedback === 'resource_not_found' ? 'Página no encontrada' : 'Algo salió mal',
    })
}

const sitemap = ref(responseData.value) as Ref<SitemapPromos>

const { $lang } = useNuxtApp()
const metaInfo = {
    title: `Mapa del sitio - ${$lang.brand}`,
    description: `Mapa del sitio de ${$lang.brand}. Consulta categorías, tiendas, cupones, ofertas y promociones de forma ordenada y accesible.`,
}
const {
    public: { origin },
} = useRuntimeConfig()

useSeoMeta({
    title: metaInfo.title,
    ogUrl: origin + Route.path,
    ogTitle: metaInfo.title,
    description: metaInfo.description,
    ogDescription: metaInfo.description,
    ogImageAlt: metaInfo.title,
    twitterCard: 'summary_large_image',
})

useHead({
    link: [
        {
            rel: 'canonical',
            href: origin + Route.path,
        },
    ],
})
</script>
<style lang="postcss">
.sitemap-discounts-index {
    &__letter-sec {
        @apply py-4 sm:py-6 lg:py-8;
        &:not(:last-of-type) {
            @apply border-b border-gray-200;
        }
    }
    &__title {
        @apply block text-lg font-medium capitalize;
    }
    &__letter-list {
        @apply my-6 grid gap-5 leading-none xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4;
        a {
            @apply lg:hover:underline;
        }
    }
}
</style>
